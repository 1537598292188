.container-ancestry {
  font-family: 'Montserrat';
  font-size: 14px;
  color: black;
  padding: 20px 0;
  background-color: white;
  border-radius: 15px;
  overflow: hidden;

  @media (max-width: 768px) {
    border-radius: 0;
  }

  .controls {
    border: 1px solid #ccc;
    width: 200px;
    display: flex;
    padding: 0;
    margin: 10px auto;

    .option {
      background-color: #ccc;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 10px;
      cursor: pointer;

      &.selected { 
        background-color: #F48F20;
        color: #fff;
      }

      &:focus { outline: thin dotted; }
    }
    h3 { font-size: 1em; margin: 0; }
  }


  .chart-wrapper {
    display: flex;
    justify-content: center;
    padding: 20px;
    overflow: hidden;

    @media (max-width: 768px) {
      overflow: scroll;
      justify-content: flex-start;
    }
  }
}

p {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 14px;
  color: #707070;
  font-weight: 600;
}

.world-container {
  position: relative;

  @media (max-width: 315px) {
    overflow: scroll;
  }

  .world {
    @media (max-width: 315px) {
      max-width: none;
      width: 200%;
    }
  }

  .animated {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    &:hover {
      z-index: 99;
      .bubble-label {
        visibility: visible;
      }
    }

    div.bubble-label {
      position: absolute;
      background-color: #333;
      color: #fff;
      padding: 5px 10px;
      border-radius: 5px;
      visibility: hidden;
      z-index: 99;

      h4 {
        font-size: 1em;
        padding: 0;
        margin: 0;
      }
    }
  }
  
  .circle {
    background-color: #F48F20;
    box-shadow: 1px 1px #ccc;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    position: absolute;
    transition: all .2s ease-in-out;

    &:hover {
      transform: scale(1.1);
    }
  }
}