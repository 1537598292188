.register {
  .form-label {
    margin: 10px 0 0 0;
  }
}

.registration-form-enter {
  // opacity: 0.01;
  left: 150%;
  position: relative;
}

.registration-form-enter.registration-form-enter-active {
  // opacity: 1;
  left: 0;
  // transition: opacity 500ms ease-in;
  transition: left 500ms ease-in;
}

.registration-form-leave {
  // opacity: 1;
  position: relative;
  right: 0;
}

.registration-form-leave.registration-form-leave-active {
  // opacity: 0.01;
  // transition: opacity 300ms ease-in;
  right: 150%;
  transition: right 300ms ease-in;
}
