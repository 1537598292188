.back-btn {
  float: left;

  button {
    background-color: #fff;
    border: none;
  }

  a,
  a:hover,
  button {
    color: #707070;
    font-family: "Montserrat";
    display: flex;
    align-items: center;
    text-decoration: none;

    .flipped {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
    }

    h4 {
      margin: 0 0 0 5px;
    }
  }
}
