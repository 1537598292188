.settings {
  padding-bottom: 20px;
  max-width: 600px;
}

.settings-modal {
  position: absolute;
  top: 30%;
  // bottom: 200px;
  left: 30%;
  right: 30%;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000080;
  border-radius: 10px;
  font-family: "Montserrat";
  overflow: hidden;
  display: flex;
  flex-direction: column;
  text-align: center;

  @media (max-width: 768px) {
    top: 10%;
    // bottom: 10%;
    left: 15%;
    right: 15%;
  }

  .modal-heading {
    background-color: #fca60a;
    color: #fff;
    padding: 20px;
    text-align: center;

    h1 {
      font-size: 1.6em;
      @media (max-width: 768px) {
        font-size: 1.1em;
      }
    }
  }

  .modal-footer {
    justify-content: center;

    .orange {
      background-color: #fca60a;
      border-color: #fca60a;
    }
  }
}
