.profile {
  font-family: "Montserrat";

  h1 {
    margin-top: 20px;
    margin-bottom: 30px;
    font-family: "Montserrat";
    font-size: 30px;
    color: #707070;
    font-weight: 600;
  }

  .profile-container {
    background-color: white;
    border-radius: 15px;
    padding: 10px;
    box-sizing: border-box;

    .table {
      margin: 0 auto;
      font-size: 14px;
      width: 90%;
      background-color: #f0f0f0;
      border-radius: 20px;
      border-bottom: 0px;
      text-align: left;
      padding: 10px 20px;

      .label {
        padding: 10px;
        padding-right: 30px;
        width: 15px;
        font-weight: bold;
      }

      .data {
        padding: 10px;
      }

      .segment {
        border-bottom: 1px solid #ccc;
      }

      .centered {
        justify-content: center;
      }
    }
  }

  h2 {
    font-size: 1em;
    font-weight: 500;
    margin-left: 15px;
    text-align: left;
  }

  button {
    border-color: #fca60a;
    background: #fca60a;
    border-radius: 10px;
    margin: 10px;

    &.wide {
      width: 162px;
      height: 80px;
      margin: 10px 0 0 0;
    }
  }
}
