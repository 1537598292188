@import '../mixins';

.ReactModal__Overlay {
  z-index: 1000;
}

.document-modal {
  position: absolute;
  top: 10vh;
  // bottom: 200px;
  left: 10vh;
  right: 10vh;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000080;
  border-radius: 10px;
  font-family: "Montserrat";
  overflow: hidden;
  display: flex;
  flex-direction: column;
  text-align: center;
  max-height: 80vh;
  z-index: 1000;

  @include for-ie {
    box-shadow: 0px 3px 6px #000;
  }

  .modal-body {
    overflow-y: auto;
    @include for-ie {
      overflow-y: scroll;
    }
    word-break: break-word;
  }

  .modal-heading {
    background-color: #fca60a;
    color: #fff;
    padding: 20px;
    text-align: center;

    h1 {
      font-size: 1.6em;
      @media (max-width: 768px) {
        font-size: 1.1em;
      }
    }
  }

  .modal-footer {
    justify-content: center;
    min-height: 80px;

    .orange {
      background-color: #fca60a;
      border-color: #fca60a;
    }
  }
}

.sliding-container {
  .modal-body {
    display: flex;
    flex-direction: column;
    flex: 1;

    &.hidden {
      display: none;
    }
  }

  .modal-heading.hidden {
    display: none;
  }
}
