@import '../mixins.scss';

$lightorange: #fca60a;
$track: #f9c68e;

.discover-panel {
  justify-content: center;
  background-color: #ffffff;
  border-radius: 50px;
  overflow: hidden;
  box-shadow: 0px 3px 10px #00000080;
  align-self: center;
  max-height: 87%;
  margin: 0 auto;
  font-family: 'Montserrat';

  @include for-ie {
    height: 600px;
    min-width: 70vw;
  }

  @media (max-width: 768px) {
    border-radius: 0;
  }

  .left-column {
    img {
      width: 80%;
      margin: 20px;
      margin-bottom: 10px;

      @media (max-width: 768px) {
        max-width: 200px;
      }
    }

    p {
      font-size: 12px;
      width: 100%;
      color: #fff;
      font-weight: 600;
    }
  }

  h1 {
    font-size: 50px;
    color: #fca60a;
    margin: 0 0 40px;
  }

  & > .col:first-child {
    flex: 1;
    display: flex;
    background-color: $lightorange;
    align-items: center;
    justify-content: center;
    z-index: 5;
  }

  & > .col:last-child {
    flex: 2;
    margin: 10px 30px;
    padding-bottom: 30px;
    max-height: 100%;
    @media (max-width: 768px) {
      margin: 10px 20px;
    }

    @include for-ie {
      overflow-y: scroll;
    }
  }

  .col {
    padding: 10px;

    ::-webkit-scrollbar {
      width: 1em;
    }

    ::-webkit-scrollbar-track {
      margin: 10px;
      background-color: $track;
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: $lightorange;
      border-radius: 10px;
      height: 20px;
    }

    form {
      @media (min-width: 768px) {
        margin-top: 20px;
      }
    }
  }
}
