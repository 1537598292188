.appointments {
  // h1 {
  //   font-size: 30px;
  //   color: #707070;
  //   font-weight: 600;
  // }

  // p {
  //   margin-top: 20px;
  //   margin-bottom: 20px;
  //   font-size: 14px;
  //   color: #707070;
  //   font-weight: 600;
  // }

  .content {
    // font-size: 14px;
    // color: white;
    // padding-bottom: 20px;
    // background-color: #fff;
    // border-radius: 15px;
    // flex: 1;
    // display: flex;
    // flex-direction: column;
    // max-height: 55vh;
    // overflow: hidden;
  }

  $dark: #70AD47;
  $light: #B7D6A2;

  ::-webkit-scrollbar {
    width: 1em;
  }

  ::-webkit-scrollbar-track {
    margin: 10px;
    background-color: $light;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $dark;
    border-radius: 10px;
    height: 20px;
  }
}