.FAQContainer {
  width: 100%;
  text-align: left;
  font-family: 'Montserrat';

  .warning {
    color: #f00;
  }

  ol {
    list-style-type: none;
    counter-reset: item;
    padding-left: 10px;
    font-size: 16px;
    text-align: left !important;
    border-bottom: 0px solid rgb(209, 136, 0);
    border-top: 2px solid rgb(209, 136, 0);
    padding: 10px;
    color: rgb(209, 136, 0);
    ol {
      border-bottom: 2px solid rgb(209, 136, 0);
      border-top: 2px solid rgb(209, 136, 0);
    }
  }

  :not(ul) > li:before {
    content: counters(item, '.') ' ';
    counter-increment: item;
  }

  ul {
    margin-bottom: 10px;
  }

  h1 {
    text-align: left;
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: bold;
    border-bottom: 3px solid rgba(252, 166, 10, 0.8);
    border-top: 2px solid rgba(252, 166, 10, 0.8);
    padding: 5px;
    color: rgba(252, 166, 10, 0.8);
  }

  p li {
    display: block;
    font-size: 14px;
    text-align: left !important;
  }
  h2 {
    font-size: 16px;
    text-align: left !important;
  }

  ul {
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 600;
    color: #707070;
  }

  .sectionLink {
    background-color: #fff;
    border: none;
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline;
  }
}
