.data-table {
  margin: 0;
  padding: 10px;
  justify-content: center;

  .header {
    color: #707070;
    font-weight: 600;
    text-align: left;
    padding: 10px 30px;
  }

  .data-table-row {
    text-align: left;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    box-sizing: border-box;
    border-radius: 10px;
    margin: 10px;
    padding: 10px;
    .col {
      flex-shrink: 0;
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-word;
    }
  }

  .data-table-tb {
    border-collapse: separate;
    border-spacing: 0 15px;

    tbody {
      tr {
        color: #fff;
      }

      tr td:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      tr td:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }

  .tr-prescriptions-color {
    td {
      background-color: #406e86;
    }
  }
  .tr-prescriptions-offcolor {
    td {
      background-color: #9eb5c2;
    }
  }
  .tr-allergies-color {
    td {
      background-color: #8336c2;
    }
  }
  .tr-allergies-offcolor {
    td {
      background-color: #c099e0;
    }
  }
  .tr-conditions-color {
    td {
      background-color: #F48F20;
    }
  }
  .tr-conditions-offcolor {
    td {
      background-color: #F9C68E;
    }
  }
  .tr-appointments-color {
    td {
      background-color: #70AD47;
    }
  }
  .tr-appointments-offcolor {
    td {
      background-color: #B7D6A2;
    }
  }
}
