$dark: #EC2C58;
$light: #F594AA;

.timeline {
  .timeline-wrapper {
    width: 75vw;
    overflow-x: scroll;
    direction: rtl;

    ::-webkit-scrollbar {
      width: 1em;
    }
  
    ::-webkit-scrollbar-track {
      margin: 10px;
      background-color: $light;
      border-radius: 10px;
    }
  
    ::-webkit-scrollbar-thumb {
      background-color: $dark;
      border-radius: 10px;
      height: 20px;
    }
  }
}