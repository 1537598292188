$dark: #406E86;
$light: #9EB5C2;

.scrollbar-prescriptions {
  ::-webkit-scrollbar {
    width: 1em;
  }

  ::-webkit-scrollbar-track {
    margin: 10px;
    background-color: $light;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $dark;
    border-radius: 10px;
    height: 20px;
  }
}

.container-prescriptions {
  font-family: 'Montserrat';
  font-size: 14px;
  color: white;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: white;
  border-radius: 15px;
  scrollbar-color: $dark;

}

.information {
  background-image: url('../../images/icons/information.svg');
  background-repeat: no-repeat;
  display: inline-block;
  width: 18px;
  height: 18px;
  text-align: center;
  font-size: .8em;
  line-height: 1.6em;
  margin-right: 10px;
  // vertical-align: middle;
  // font-weight: bold;
}