$lightorange: #fca60a;

.right-column {
  font-family: 'Montserrat';

  @media (max-width: 768px) {
    min-width: 90%;
  }

  h2 {
    margin-top: 20px;
    font-size: 27px;
    text-align: center;
    width: 100%;
    font-weight: 400;
    @media (max-width: 768px) {
      margin-top: 0;
      font-size: 20px;
    }
  }

  h1 {
    margin-top: 20px;
    text-align: center;
    width: 100%;
    font-weight: 400;
    font-size: 50px;
    color: $lightorange;
    margin: 0 0 40px 0;
    @media (max-width: 768px) {
      font-size: 30px;
      margin-bottom: 10px;
    }
  }

  input[type='text'] {
    border-radius: 12px;
  }

  .form-label {
    font-weight: bold;
    display: flex;
    align-items: center;

    button.info {
      background: none;
      border: none;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
    }
  }

  .login-btn {
    margin-top: 20px;
    border-color: $lightorange;
    background-color: $lightorange;
    padding: 5px 40px;
    font-size: 1.1em;
    font-weight: 500;
    border-radius: 7.5px;
    align-self: center;
  }

  .forgotten {
    margin-top: 10px;
  }

  .login-form {
    justify-content: center;
    text-align: left;
    font-size: 14px;
    font-weight: 300;
    display: flex;
    flex-direction: column;
  }
}
