$dark: #8336c2;
$light: #c099e0;

.scrollbar-allergies{
  ::-webkit-scrollbar {
    width: 1em;
  }

  ::-webkit-scrollbar-track {
    margin: 10px;
    background-color: $light;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $dark;
    border-radius: 10px;
    height: 20px;
  }
}

.row-allergies {
  background-color: $light;
  text-align: left;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 10px;
  padding: 10px;

  &:nth-child(odd) {
    background-color: $dark;
  }

  &.disabled {
    background-color: transparent;
    color: #333;
  }
}

.information {
  background-image: url('../../images/icons/information.svg');
  background-repeat: no-repeat;
  display: inline-block;
  width: 18px;
  height: 18px;
  text-align: center;
  font-size: .8em;
  line-height: 1.6em;
  margin-right: 10px;
  // vertical-align: middle;
  // font-weight: bold;
}