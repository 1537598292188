.vaccinations {
  padding-bottom: 20px;
  max-width: 800px;
  font-family: "Montserrat";
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;

  h1 {
    font-size: 30px;
    color: #707070;
    font-weight: 600;
  }

  p {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #707070;
    font-weight: 600;
  }

  .content {
    font-size: 14px;
    color: white;
    padding-bottom: 20px;
    background-color: #fff;
    border-radius: 15px;
    flex: 1;
    display: flex;
    flex-direction: column;


    }
  

  $dark: #EC2C58;
  $light: #F594AA;

  ::-webkit-scrollbar {
    width: 1em;
  }

  ::-webkit-scrollbar-track {
    margin: 10px;
    background-color: $light;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $dark;
    border-radius: 10px;
    height: 20px;
  }

  // data table overflow

  // header
  // style={{
  //   paddingTop: 20, color: '#707070', borderRadius: 20, margin: 0, background: 'white',
  // }}
  }
