.content {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 1px 2px #3332;
  padding: 15px;
}

.checkbox-container {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.checkbox {
  border: 2px solid #ccc;
  cursor: pointer;
  height: 30px;
  width: 30px;
}

.checkbox > span {
  color: #fca613;
  display: none;
}

.checked > span {
  display: block;
}

.button {
  background-color: #fca613;
  border-color: #fca613;
}

.address-form {
  margin: 15px 0;
  width: 300px;
}

.address-form .form-group {
  display: flex;
}

.address-group {
  align-items: flex-start;
  color: #707070;
  display: flex;
  flex-direction: column;
}

.page-title {
  color: #707070;
  margin-bottom: 20px;
}

.error {
  display: block;
  text-align: left;
}
