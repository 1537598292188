.home {
  margin-top: 20px;

  h1 {
    margin: 5px 0;
    font-family: 'Montserrat';
    font-size: 2em;

    span {
      color: #fca60a;
    }
  }
}

// style={{
//   marginTop: 5,
//   marginBottom: 5,

//   fontSize: '150%',
//   alignText: 'center',
//   width: '100%',
//   color: '#707070',
//   fontWeight: 600,
// }}
