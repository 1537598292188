@import './mixins';

.App {
  min-height: 100vh;
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: center;
  background-color: #eee;

  @include for-ie {
    height: 100vh;
    overflow: scroll;
  }

  &.center {
    background-image: url('./images/background/BACKGROUND@2x.png');
    background-position: center;
    background-size: cover;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    // background-image: url("./images/background/BACKGROUND@2x.png");
    // background-position: center;
    // background-size: cover;
    background-color: #eee;
  }

  .inner {
    margin: 20px auto;
    padding: 10px;
    max-width: 800px;
    flex: 1;
    display: flex;
    flex-direction: column;

    @include for-ie {
      align-self: center;
      width: 800px;
      flex: 1 0 0px;
    }

    @media (min-width: 768px) {
      min-width: 700px;
    }

    @media (max-width: 768px) {
      max-width: 100%;
      
      &.mobile-login {
        margin: 0;
        padding: 0;
      }
    }

    &.wide {
      max-width: 100%;
    }
  }

  .dob-error {
    margin-top: calc(-1rem + -10px);
  }

  footer {
    padding: 3px;
    color: #aaa;
    background-color: #333;
    display: flex;
    flex: 1;

    @include for-ie {
      bottom: 0;
      position: fixed;
      width: 100%;
    }

    div {
      flex: 1;
      text-align: left;
    }

    .right {
      text-align: right;
    }

    &.shadow {
      text-shadow: 1px 1px #333;
    }

    .footer-btn {
      background-color: transparent;
      border: none;
      color: #aaa;

      &.shadow {
        text-shadow: 1px 1px #333;
      }
    }
  }

  p.success {
    color: green;
    font-size: 0.8em;
  }
}

.MainApp {
  // overflow-y: auto;
  // min-height: 100vh;

  &.center {
    background-image: url('./images/background/BACKGROUND@2x.png');
    background-position: center;
    background-size: cover;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    // background-image: url("./images/background/BACKGROUND@2x.png");
    // background-position: center;
    // background-size: cover;
  }
}

.navbar-nav {
  align-items: center;

  .dropdown-menu {
    @media (max-width: 768px) {
      position: absolute!important;
      left: -65px;
    }
  }

  .nav-item {
    cursor: pointer;
  }
}

.navigation-bar {
  flex-direction: row !important;
  justify-content: center;
}

.profile-button {
  display: none;
}

.modal-header {
  background-color: #fca60a;
}

.modal-spinner {
  display: flex;
  flex-direction: center;
}

.btn {
  :not(.help) {
    border-color: #fca60a !important;
    background: #fca60a !important;
    border-radius: 10px;
  }
}

.nav-help {
  border: none;
  background-color: transparent;
  padding: 0 5px 0 14px;

  &:focus {
    outline: none;
  }
}

.btn-secondary {
  border-color: #aaaaaa !important;
  background: #aaaaaa !important;
  border-radius: 10px;
}

.close {
  color: white !important;
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #fca60a; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.health-data-content {
  font-size: 14px;
  color: white;
  padding-bottom: 20px;
  background-color: #fff;
  border-radius: 15px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.row {
  &.disabled {
    padding: 10px;
    background-color: transparent;
    color: #333;
    justify-content: center;
  }
}

.tos-btn {
  font-size: 1.3em;
  font-weight: 600;
  min-height: 40px;
}

.switch_container {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #fca60a;
  padding: 20px;
  border-radius: 5px;

  &.error {
    border-color: #c11f47;
  }

  h4 {
    font-size: 1.2em;
    flex: 1;
    text-align: left;
    margin: 0;
  }

  p.error {
    color: red;
    margin-top: 20px;
    font-size: 1.3em;
  }

  .control {
    display: flex;
    align-items: center;
    text-align: left;
    justify-content: center;
    width: 50%;
    margin-top: 20px;

    label {
      flex: 1;
      padding-left: 20px;
    }

    input {
      max-width: 20px;
    }
  }
}

h2.page-title {
  margin-top: 20;
  font-family: Montserrat;
  font-size: 30px;
  text-align: center;
  width: 100%;
  color: #707070;
  font-weight: 600;
}

div.page-context {
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: Montserrat;
  font-size: 14px;
  text-align: center;
  color: #707070;
  font-weight: 600;
}

.popover-body {
  p {
    color: #000;
    font-weight: normal;

    button {
      color: rgb(231, 156, 17);
      cursor: pointer;
      text-decoration: underline;
      border: none;
      background-color: #fff;
      padding: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}