.infoSheetContainer {
  max-height: 1000px;
  overflow-y: scroll;
  max-width: 768px;
  width: 100%;
  min-width: 300px;
  

}
.infoContainer h3,
.infoSheetContainer h3 {
  text-align: center;
  font-weight: bold;
  border-bottom: 3px solid #d29c14;
  border-top: 2px solid #d29c14;
  padding: 5px;
  color: #d29c14;
}
.infoContainer h4,
.infoContainer h4 {
  border-bottom: 3px solid #d29c14;
  padding: 5px;
  text-align: center;
}
.infoContainer div p:first-child,
.infoSheetContainer div p:first-child {
  border-top: 3px solid #d29c14;
  border-bottom: 3px solid #d29c14;
  padding: 5px;
  text-align: left !important;
}

.infoContainer {
  text-align: left;
  li {
    font-weight: bold;
    font-size: 14px;
    color: #707070;
    font-weight: 600;
  }

}
