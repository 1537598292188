.PrivacyPolicyContainer {
  width: 100%;
  text-align: left;
  font-family: 'Montserrat';

  h1 {
    text-align: left;
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: bold;
    border-bottom: 3px solid rgb(209, 136, 0);
    border-top: 2px solid rgb(209, 136, 0);
    padding: 5px;
    color: rgb(209, 136, 0);
  }

  p li {
    font-size: 14px;
    text-align: left !important;
  }
  h2 {
    font-size: 16px;
    text-align: left !important;
  }

  ul {
    font-family: 'Montserrat';
    font-size: 14px;
    font-weight: 600;
    color: #707070;

  }
}
