.row-conditions {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  box-sizing: border-box;
  background-color: #F48F20;
  border-radius: 10px;
  margin: 0;
  margin: 10px;
  padding: 10px;
  text-align: left;

  &:nth-child(odd) {
    background-color: #F9C68E;
  }

  &.disabled {
    background-color: transparent;
    color: #333;
    text-align: center;
  }
}


.scrollbar-conditions{
::-webkit-scrollbar {
  width: 1em;
}

::-webkit-scrollbar-track {
  margin: 10px;
  background-color: #F9C68E;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #F48F20;
  border-radius: 10px;
  height: 20px
}
}