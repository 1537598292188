.react-switch-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
  }
  
  .react-switch-label {
    cursor: pointer;
    top: 5px;
    width: 40px;
    height: 15px;
    background: #d1d1d1;
    border-radius: 50px;
    position: relative;
    transition: background-color 0.2s;
  }
  
  .react-switch-label .react-switch-button {
    content: '';
    position: absolute;
    top: -4px;
    left: 1px;
    width: 22px;
    height: 22px;
    border-radius: 22px;
    transition: 0.2s;
    background: #aaa;
    box-shadow: 0 0 1px 0 rgba(10, 10, 10, 0.29);
  }
  
  .react-switch-checkbox:checked + .react-switch-label .react-switch-button {
    left: calc(100% - 1px);
    transform: translateX(-100%);
  }
  
 