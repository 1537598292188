.top-text {
  font-family: 'Montserrat';
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: center;
  width: 100%;
}

.error-title {
  font-family: 'Montserrat';
  font-size: 26px;
  font-weight: 400;
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: center;
  width: 100%;
}

.form {
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 300;
  justify-content: center;
  text-align: left;
}

.submit {
  background: #d48600;
  border: 1px solid;
  border-color: #d48600;
  border-radius: 0.25rem;
  color: #fff;
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  margin: auto;
  padding: 0.375rem 0.75rem;
  position: relative;
  text-align: center;
  vertical-align: middle;
}

.expired-text {
  font-family: 'Montserrat';
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: center;
  width: 100%;
}
