.box-container {
  display: flex;
  align-items: stretch;
  justify-content: center;
  margin: auto;
  border-radius: 20px;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 5px;
  box-shadow: 0px 3px 6px #00000080;
  position: relative;
  cursor: pointer;

  .row {
    flex: auto;
    position: relative;
    margin: 0;
    height: 100%;
  }

  .box-text {
    display: flex;
    width: 150%;
    margin: 0;
    margin-left: -25%;
    align-items: center;
    justify-content: center;
    color: white;

    div p {
      margin: auto;
      padding: 1vh;
      font-family: Montserrat;
      font-size: 90%;
      text-align: center;
      height: 100%;
      font-weight: 600;
    }
  }
}

.box {
  div.disabled {
    background-color: #333333;
    opacity: 0.8;
    z-index: 100;
    position: absolute;
    top: 10px;
    left: 15px;
    right: 20px;
    bottom: 10px;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.darkblue {
  background-color: #406e86;
}
.lightblue {
  background-color: #74a7fe;
}
.lightred {
  background-color: #ec2c58;
}
.green {
  background-color: #70ad47;
}
.lightgreen {
  background-color: #73cda6;
}
.orange {
  background-color: #f48f20;
}
.purple {
  background-color: #8336c2;
}
.darkgrey {
  background-color: #00000080;
}

.material-icons.md-light {
  color: rgba(255, 255, 255, 1);
}
.material-icons.md-light.md-inactive {
  color: rgba(255, 255, 255, 0.3);
}
.material-icons.md-18 {
  font-size: 18px;
}
.material-icons.md-24 {
  font-size: 24px;
}
.material-icons.md-36 {
  font-size: 36px;
}
.material-icons.md-96 {
  font-size: 96px;
}
.material-icons.md-48 {
  font-size: 48px;
}
