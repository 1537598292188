.left {
    display: block;
    -moz-border-radius: 50px/50px;
    -webkit-border-radius: 50px 50px;
    border-radius: 50px/50px;
    border: solid 2px #707070;
    height: 40px;
    width: 40px;
}
.left::after {
    content:"\25c0";   /* The code for the arrow : see the reference */
    display: block;
    color: #707070;
    font-weight: bold;
    font-size: 23px; /* Adjust for your needs */
    text-align: center;
}